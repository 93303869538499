// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background: #f3f4f6;
  font-family: 'Lato', sans-serif;
}

.dot-pulse {
  --uib-size: 40px;
  --uib-speed: 1.3s;
  --uib-color: #fff;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: var(--uib-size);
  height: calc(var(--uib-size) * 0.27);
}

.dot-pulse__dot,
.dot-pulse::before,
.dot-pulse::after {
  content: '';
  display: block;
  height: calc(var(--uib-size) * 0.18);
  width: calc(var(--uib-size) * 0.18);
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
}

.dot-pulse::before {
  animation: pulse var(--uib-speed) ease-in-out infinite;
}

.dot-pulse__dot {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * 0.125) infinite both;
}

.dot-pulse::after {
  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * 0.25) infinite;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/custom.css"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;;EAEjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;EACtB,oCAAoC;AACtC;;AAEA;;;EAGE,WAAW;EACX,cAAc;EACd,oCAAoC;EACpC,mCAAmC;EACnC,kBAAkB;EAClB,kCAAkC;EAClC,mBAAmB;AACrB;;AAEA;EACE,sDAAsD;AACxD;;AAEA;EACE,0FAA0F;AAC5F;;AAEA;EACE,oFAAoF;AACtF;;AAEA;EACE;;IAEE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');\n\nbody {\n  background: #f3f4f6;\n  font-family: 'Lato', sans-serif;\n}\n\n.dot-pulse {\n  --uib-size: 40px;\n  --uib-speed: 1.3s;\n  --uib-color: #fff;\n\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: var(--uib-size);\n  height: calc(var(--uib-size) * 0.27);\n}\n\n.dot-pulse__dot,\n.dot-pulse::before,\n.dot-pulse::after {\n  content: '';\n  display: block;\n  height: calc(var(--uib-size) * 0.18);\n  width: calc(var(--uib-size) * 0.18);\n  border-radius: 50%;\n  background-color: var(--uib-color);\n  transform: scale(0);\n}\n\n.dot-pulse::before {\n  animation: pulse var(--uib-speed) ease-in-out infinite;\n}\n\n.dot-pulse__dot {\n  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * 0.125) infinite both;\n}\n\n.dot-pulse::after {\n  animation: pulse var(--uib-speed) ease-in-out calc(var(--uib-speed) * 0.25) infinite;\n}\n\n@keyframes pulse {\n  0%,\n  100% {\n    transform: scale(0);\n  }\n\n  50% {\n    transform: scale(1.5);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
